import Loading from 'quasar/src/plugins/Loading.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Notify from 'quasar/src/plugins/Notify.js';

import iconSet from './iconSet'
import './notifications'

export default {
  iconSet,
  config: {
    loading: {
      boxClass: 'mi-loading__box',
      customClass: 'mi-loading'
    },
    notify: {
      position: 'top-right',
      progressClass: 'mi-notification__progress',
      multiLine: true
    }
  },
  plugins: {
    Loading,
    LocalStorage,
    Notify
  }
}
