<template>
  <q-linear-progress
    class="mi-linear-progress"
    :value="progressValue"
    :color="color"
    :track-color="trackColor"
    :rounded="rounded"
    :size="size"
    :animation-speed="animationSpeed"
  >
    <slot></slot>
  </q-linear-progress>
</template>

<script>
  export default {
    name: 'MiLinearProgress',
    props: {
      maxDuration: {
        type: Number,
        required: false,
        default: undefined // ms
      },
      color: {
        type: String,
        required: false,
        default: ''
      },
      trackColor: {
        type: String,
        required: false,
        default: ''
      },
      rounded: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: ''
      },
      animationSpeed: {
        type: Number,
        required: false,
        default: undefined // ms
      }
    },
    data() {
      return ({
        progressValue: 0,
        intervalId: undefined
      })
    },
    computed: {
      duration() {
        return this.maxDuration || 5000 // ms
      },
      unknownDuration() {
        return this.maxDuration == null
      }
    },
    beforeMount() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId)
      }
    },
    beforeUnmount() {
      this.stop()
    },
    methods: {
      start() {
        if (this.intervalId != null) {
          clearInterval(this.intervalId)
        }

        const frequency = 10
        const rand = 0.9 + (Math.random() / 5) // 0.9 to 1.1
        const lastTick = (Math.random() / 20) + 0.95 // 0.95 to 1
        const interval = Math.min((this.duration / frequency) * rand, this.duration) // ms
        const increment = Math.min((1 / frequency) * rand, 1) // 0 to 1

        this.progressValue = increment
        const slower = this.unknownDuration

        this.intervalId = setInterval(() => {
          const inc = slower
            ? increment * Math.random() * Math.log((lastTick - this.progressValue) * 10)
            : increment * (0.5 + Math.random())
          if (this.progressValue + inc >= lastTick) {
            return
          }

          this.progressValue += inc
        }, interval)
      },
      stop() {
        if (this.intervalId != null) {
          clearInterval(this.intervalId)
        }

        this.progressValue = 1
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-linear-progress.rounded-borders {
    border-radius: 4px;
  }
</style>
