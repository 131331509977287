<template>
  <q-stepper-navigation class="flex justify-end mi-stepper-navigation">
    <slot></slot>
  </q-stepper-navigation>
</template>

<script>
  export default {
    name: 'MiStepperNavigation'
  }
</script>
