/* eslint-disable no-console */
import { register } from 'register-service-worker'

const SW_URL = `${ process.env.BASE_URL }service-worker.js`

const isSwAvailableForRegistration = process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator

if (isSwAvailableForRegistration) {
  register(SW_URL, {
    updated(registration) {
      const swUpdatedEvent = new CustomEvent('swUpdated', { detail: registration })
      document.dispatchEvent(swUpdatedEvent)
    }
  })
}
