<template>
  <q-input
    ref="qInput"
    class="mi-field"
    :autofocus="autofocus"
    :autogrow="autogrow"
    :clearable="clearable"
    :color="color"
    :debounce="debounce"
    :dense="dense"
    :disable="disable"
    :fill-mask="fillMask"
    :hide-bottom-space="hideBottomSpace"
    :hide-hint="hideHint"
    :hint="hint"
    :label="label"
    :label-slot="labelSlot"
    :lazy-rules="lazyRules"
    :loading="loading"
    :mask="mask"
    :outlined="outlined"
    :placeholder="placeholder"
    :rules="rules"
    :type="type"
    :unmasked-value="unmaskedValue"
    :error="false"
    no-error-icon
    stack-label
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-input>
</template>

<script>
  export default {
    name: 'MiTextField',
    props: {
      autofocus: {
        type: Boolean,
        required: false,
        default: false
      },
      autogrow: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      debounce: {
        type: [Number, String],
        required: false,
        default: 0
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      fillMask: {
        type: [Boolean, String],
        required: false,
        default: false
      },
      hideBottomSpace: {
        type: Boolean,
        required: false,
        default: false
      },
      hideHint: {
        type: Boolean,
        required: false,
        default: false
      },
      hint: {
        type: String,
        required: false,
        default: undefined
      },
      label: {
        type: String,
        required: false,
        default: undefined
      },
      labelSlot: {
        type: Boolean,
        required: false,
        default: false
      },
      lazyRules: {
        type: [Boolean, String],
        required: false,
        default: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      mask: {
        type: String,
        required: false,
        default: ''
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      placeholder: {
        type: String,
        required: false,
        default: ''
      },
      rules: {
        type: Array,
        required: false,
        default: () => []
      },
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      unmaskedValue: {
        type: Boolean,
        required: false,
        default: false
      },
      error: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      blur() {
        this.$refs.qInput?.blur?.()
      },
      focus() {
        this.$refs.qInput?.focus?.()
      },
      validate() {
        return this.$refs.qInput?.validate?.()
      }
    }
  }
</script>
