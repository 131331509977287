import { LIGHT, DARK, INFO, NEGATIVE, POSITIVE, WARNING, DARK_NO_ICON, DARK_INFO } from './notificationTypes'
import registerNotificationType from './registerNotificationType'

// Light (default)
registerNotificationType({
  type: LIGHT,
  textColor: 'primary',
  color: 'white',
  icon: 'info-circle'
})

// Dark
registerNotificationType({
  type: DARK,
  color: 'primary',
  icon: 'info-circle'
})

// Dark_NO_ICON
registerNotificationType({
  type: DARK_NO_ICON,
  color: 'primary',
  icon: ''
})

// Positive
registerNotificationType({
  type: POSITIVE,
  color: 'positive',
  icon: 'check-circle'
})

// Negative
registerNotificationType({
  type: NEGATIVE,
  color: 'negative',
  icon: 'error'
})

// Info
registerNotificationType({
  type: INFO,
  color: 'info',
  icon: 'info-circle'
})

// Dark Info
registerNotificationType({
  type: DARK_INFO,
  color: 'primary',
  icon: ''
})

// Warning
registerNotificationType({
  type: WARNING,
  textColor: 'primary',
  color: 'warning',
  icon: 'warning-circle'
})
