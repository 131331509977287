<template>
  <q-page :padding="padding">
    <slot></slot>
  </q-page>
</template>

<script>
  export default {
    name: 'MiPage',
    props: {
      padding: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
