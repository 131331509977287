<template>
  <q-item-section
    class="mi-list-item-section"
    :avatar="avatar"
    :no-wrap="noWrap"
    :side="side"
    :thumbnail="thumbnail"
    :top="top"
  >
    <slot></slot>
  </q-item-section>
</template>

<script>
  export default {
    name: 'MiListItemSection',
    props: {
      avatar: {
        type: Boolean,
        required: false,
        default: false
      },
      noWrap: {
        type: Boolean,
        required: false,
        default: false
      },
      side: {
        type: Boolean,
        required: false,
        default: false
      },
      thumbnail: {
        type: Boolean,
        required: false,
        default: false
      },
      top: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-list-item-section.q-item__section--side > .q-icon,
  .mi-list-item-section ::v-deep(.mi-btn) .mi-btn__icon--single {
    font-size: $mi-list-item-section-icon-size;
  }

  .mi-list-item-section.q-item__section--avatar {
    min-width: auto;
  }
</style>
