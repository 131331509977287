<template>
  <q-td :auto-width="autoWidth" :no-hover="noHover" :props="props" class="mi-td">
    <slot></slot>
  </q-td>
</template>

<script>
  export default {
    name: 'MiTd',
    props: {
      autoWidth: {
        type: Boolean,
        required: false,
        default: false
      },
      noHover: {
        type: Boolean,
        required: false,
        default: false
      },
      props: {
        type: Object,
        required: false,
        default: () => {}
      }
    }
  }
</script>
