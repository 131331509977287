<template>
  <q-item
    class="mi-list-item"
    :clickable="clickable"
    :dense="dense"
    :disable="disable"
    :inset-level="insetLevel"
    :active-class="active"
  >
    <slot></slot>
  </q-item>
</template>

<script>
  export default {
    name: 'MiListItem',
    props: {
      clickable: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      insetLevel: {
        type: Number,
        required: false,
        default: undefined
      },
      active: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-list-item {
    min-height: $mi-list-item-min-height;
    font-size: $mi-list-item-font-size;
    color: $mi-list-item-text-color;

    &.q-item--dense {
      min-height: $mi-list-item-dense-min-height;
    }

    .disabled,
    [disabled] {
      // stylelint-disable declaration-no-important
      color: $mi-silver-300 !important;
      opacity: 1 !important;
    }
  }
</style>
