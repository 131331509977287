<template>
  <q-stepper
    ref="qStepper"
    class="mi-stepper no-shadow"
    :class="{ 'mi-stepper--no-delimiter': noDelimiter }"
    :active-color="activeColor"
    :active-icon="activeIcon"
    :animated="animated"
    :bordered="bordered"
    :done-color="doneColor"
    :done-icon="doneIcon"
    :error-color="errorColor"
    :error-icon="errorIcon"
    :header-nav="false"
    :infinite="infinite"
    :swipeable="swipeable"
    :vertical="vertical"
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-stepper>
</template>

<script>
  export default {
    name: 'MiStepper',
    props: {
      activeColor: {
        type: String,
        required: false,
        default: 'primary'
      },
      activeIcon: {
        type: String,
        required: false,
        default: ''
      },
      animated: {
        type: Boolean,
        required: false,
        default: true
      },
      bordered: {
        type: Boolean,
        required: false,
        default: false
      },
      doneColor: {
        type: String,
        required: false,
        default: 'positive'
      },
      doneIcon: {
        type: String,
        required: false,
        default: ''
      },
      errorColor: {
        type: String,
        required: false,
        default: 'negative'
      },
      errorIcon: {
        type: String,
        required: false,
        default: ''
      },
      infinite: {
        type: Boolean,
        required: false,
        default: false
      },
      noDelimiter: {
        type: Boolean,
        required: false,
        default: false
      },
      swipeable: {
        type: Boolean,
        required: false,
        default: false
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      goTo(panelName = '') {
        this.$refs.qStepper?.goTo?.(panelName)
      },
      previous() {
        this.$refs.qStepper?.previous?.()
      },
      next() {
        this.$refs.qStepper?.next?.()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-stepper.q-stepper ::v-deep(.q-stepper__dot):before,
  .mi-stepper.q-stepper ::v-deep(.q-stepper__dot):after {
    background-color: $mi-stepper-default-color;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__dot):before {
    margin-right: $mi-stepper-line-horizontal-offset;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__dot):after {
    margin-left: $mi-stepper-line-horizontal-offset;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__header--border) {
    border-color: $mi-stepper-default-color;
  }

  .mi-stepper--no-delimiter.q-stepper ::v-deep(.q-stepper__header--border) {
    border: 0;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__header--alternative-labels) .q-stepper__tab {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__tab) {
    color: $mi-stepper-default-color;

    &:first-child,
    &:last-child {
      flex-grow: 0;
    }
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__title) {
    color: $mi-black;
  }

  .mi-stepper.q-stepper ::v-deep(.q-stepper__dot) {
    font-weight: 700;
    font-family: $mi-typography-font-family-condensed;
    line-height: 1;

    .q-icon {
      font-size: $mi-stepper-dot-icon-size;
    }
  }
</style>
