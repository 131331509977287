<template>
  <q-markup-table
    class="mi-markup-table"
    :bordered="bordered"
    :dense="dense"
    :flat="flat"
    :separator="separator"
    :square="square"
    :wrap-cells="wrapCells"
  >
    <slot></slot>
  </q-markup-table>
</template>

<script>
  export default {
    name: 'MiMarkupTable',
    props: {
      bordered: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      flat: {
        type: Boolean,
        required: false,
        default: true
      },
      separator: {
        type: String,
        required: false,
        default: 'horizontal'
      },
      square: {
        type: Boolean,
        required: false,
        default: true
      },
      wrapCells: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
