<template>
  <q-resize-observer ref="qResizeObserver">
    <slot></slot>
  </q-resize-observer>
</template>

<script>
  export default {
    name: 'MiResizeObserver',
    methods: {
      trigger(immediately = false) {
        this.$refs.qResizeObserver?.trigger?.(immediately)
      }
    }
  }
</script>
