<template>
  <q-separator
    :color="color"
    :inset="inset"
    :size="size"
    :spaced="spaced"
    :vertical="vertical"
  ></q-separator>
</template>

<script>
  export default {
    name: 'MiSeparator',
    props: {
      color: {
        type: String,
        required: false,
        default: ''
      },
      inset: {
        type: [Boolean, String],
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: '2px'
      },
      spaced: {
        type: [Boolean, String],
        required: false,
        default: false
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
