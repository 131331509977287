<template>
  <q-toggle
    class="mi-switch"
    :class="!label && 'mi-switch__label--disabled'"
    :color="color"
    :disable="disable"
    :label="label"
    :size="size"
    dense
  ></q-toggle>
</template>

<script>
  export default {
    name: 'MiSwitch',
    props: {
      color: {
        type: String,
        required: false,
        default: 'accent'
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        required: false,
        default: ''
      },
      size: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-switch {
    color: $mi-silver-400;

    &__label--disabled {
      ::v-deep(.q-toggle__label) {
        display: none;
      }
    }

    ::v-deep(.q-toggle__inner) {
      width: $mi-switch-width;
      font-size: $mi-switch-font-size;
      height: $mi-switch-font-size / 2;
      padding: 0;

      .q-toggle__track {
        opacity: 1;
        height: 100%;
        border-radius: $mi-switch-border-radius;
      }
    }

    ::v-deep(.q-toggle__thumb) {
      width: $mi-switch-thumb-size;
      height: .4em;
      top: 2px;
      left: 2px;

      &:before {
        display: none;
      }

      &:after {
        color: $mi-switch-thumb-color;
        box-shadow: 0 0 2px rgba(0 0 0 / .14), 0 2px 2px rgba(0 0 0 / .12), 0 1px 3px rgba(0 0 0 / .2);
      }
    }

    ::v-deep(.q-toggle__inner--truthy) .q-toggle__thumb {
      left: .555em;
    }

    ::v-deep(.q-toggle__label) {
      padding-left: $mi-switch-label-padding-left;
      color: $mi-switch-label-color;
      line-height: 1.25;
    }

    &.disabled,
    &[disabled] {
      color: $mi-silver-200;

      ::v-deep(.q-toggle__track) {
        background-color: $mi-silver-200;
      }

      ::v-deep(.q-toggle__label) {
        color: $mi-silver-300;
      }

      ::v-deep(.q-toggle__thumb:after) {
        box-shadow: none;
      }
    }
  }
</style>
