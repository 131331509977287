export default {
  name: 'man-intelligence-icons',
  arrow: {
    down: 'caret-down',
    dropdown: 'chevron-1-down',
    left: 'caret-left',
    right: 'caret-right',
    up: 'caret-up'
  },
  carousel: {
    down: 'chevron-1-down',
    left: 'caret-left',
    right: 'caret-right',
    up: 'chevron-1-up'
  },
  chevron: {
    left: 'chevron-1-left',
    right: 'chevron-1-right'
  },
  chip: {
    remove: 'close',
    selected: 'check-circle'
  },
  datetime: {
    arrowLeft: 'chevron-1-left',
    arrowRight: 'chevron-1-right'
  },
  expansionItem: {
    denseIcon: 'caret-down',
    icon: 'caret-down'
  },
  fab: {
    icon: 'plus',
    activeIcon: 'close'
  },
  field: {
    clear: 'close-circle',
    error: 'error'
  },
  pagination: {
    first: 'chevron-2-left',
    last: 'chevron-2-right',
    next: 'chevron-1-right',
    prev: 'chevron-1-left'
  },
  stepper: {
    active: 'edit',
    done: 'check',
    error: 'warning-circle'
  },
  table: {
    arrowUp: 'caret-up',
    firstPage: 'chevron-2-left',
    lastPage: 'chevron-2-right',
    nextPage: 'chevron-1-right',
    prevPage: 'chevron-1-left',
    warning: 'warning-circle'
  },
  tabs: {
    down: 'chevron-1-down',
    left: 'chevron-1-left',
    right: 'chevron-1-right',
    up: 'chevron-1-up'
  },
  type: {
    info: 'info-circle',
    negative: 'error',
    positive: 'check-circle',
    warning: 'warning-circle',
    lock: 'lock-circle'
  },
  uploader: {
    add: 'plus-circle'
  }
}
