<template>
  <q-page-container>
    <slot></slot>
  </q-page-container>
</template>

<script>
  export default {
    name: 'MiPageContainer'
  }
</script>
