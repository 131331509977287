<template>
  <q-tabs
    class="mi-tabs"
    content-class="mi-tabs__content"
    :active-color="activeColor"
    :active-bg-color="activeBgColor"
    :align="align"
    :indicator-color="indicatorColor"
    :shrink="shrink"
    :stretch="stretch"
    :switch-indicator="switchIndicator"
    :vertical="vertical"
    inline-label
    no-caps
  >
    <slot></slot>
  </q-tabs>
</template>

<script>
  export default {
    name: 'MiTabs',
    props: {
      activeColor: {
        type: String,
        required: false,
        default: 'accent'
      },
      activeBgColor: {
        type: String,
        required: false,
        default: ''
      },
      align: {
        type: String,
        required: false,
        default: 'left'
      },
      indicatorColor: {
        type: String,
        required: false,
        default: 'accent'
      },
      shrink: {
        type: Boolean,
        required: false,
        default: false
      },
      stretch: {
        type: Boolean,
        required: false,
        default: false
      },
      switchIndicator: {
        type: Boolean,
        required: false,
        default: false
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-tabs ::v-deep(.q-tabs__arrow) {
    font-size: 16px;
    color: $mi-anthracite-800;
  }
</style>
