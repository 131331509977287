<template>
  <q-pagination
    class="mi-pagination"
    :class="{ 'mi-pagination--direction-links': directionLinks }"
    :color="color"
    :boundary-links="boundaryLinks"
    :boundary-numbers="boundaryNumbers"
    :dense="dense"
    :disable="disable"
    :direction-links="directionLinks"
    :ellipses="ellipses"
    :max="parseInt(max)"
    :max-pages="parseInt(maxPages)"
    :min="parseInt(min)"
    :padding="padding"
    :size="size"
    :text-color="textColor"
    unelevated
  ></q-pagination>
</template>

<script>
  export default {
    name: 'MiPagination',
    props: {
      boundaryLinks: {
        type: Boolean,
        required: false,
        default: false
      },
      boundaryNumbers: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      directionLinks: {
        type: Boolean,
        required: false,
        default: false
      },
      ellipses: {
        type: Boolean,
        required: false,
        default: false
      },
      max: {
        type: [Number, String],
        required: true
      },
      maxPages: {
        type: [Number, String],
        required: false,
        default: 0
      },
      min: {
        type: [Number, String],
        required: false,
        default: 1
      },
      padding: {
        type: String,
        required: false,
        default: '5px 16px'
      },
      size: {
        type: String,
        required: false,
        default: ''
      },
      textColor: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* stylelint-disable declaration-no-important */
  .mi-pagination ::v-deep(.q-btn) {
    letter-spacing: .4px;
  }

  .mi-pagination ::v-deep(.bg-primary) {
    background-color: #fde2e8 !important; // replace with DS tokens when its available
    color: #c20041 !important;
  }

  .mi-pagination ::v-deep(.q-btn__content) {
    font-weight: 400;

    .q-icon {
      font-size: $mi-pagination-arrow-icon-size;
    }
  }

  .mi-pagination--direction-links > ::v-deep(.q-btn:first-child),
  .mi-pagination--direction-links > ::v-deep(.q-btn:last-child) {
    padding: $mi-pagination-arrow-icon-padding !important;

    &.disabled,
    &[disabled] {
      color: $mi-silver-300 !important;
      opacity: 1 !important;
      font-size: 14px;
    }
  }
</style>
