<template>
  <q-list
    :bordered="bordered"
    :dense="dense"
    :padding="padding"
    :separator="separator"
    :virtual-scroll-horizontal="virtualScrollHorizontal"
    :virtual-scroll-slice-size="virtualScrollSliceSize"
  >
    <slot></slot>
  </q-list>
</template>

<script>
  export default {
    name: 'MiList',
    props: {
      bordered: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      padding: {
        type: Boolean,
        required: false,
        default: false
      },
      separator: {
        type: Boolean,
        required: false,
        default: false
      },
      virtualScrollHorizontal: {
        type: Boolean,
        required: false,
        default: false
      },
      virtualScrollSliceSize: {
        type: Number,
        required: false,
        default: 30
      }
    }
  }
</script>
