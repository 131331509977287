<template>
  <q-tab
    class="mi-tab"
    :disable="disable"
    :icon="icon"
    :label="label"
    :ripple="ripple"
    no-caps
  >
    <slot></slot>

    <!-- Badge -->
    <slot name="badge">
      <mi-badge
        v-if="badge.toString().length"
        :color="badgeColor"
        :class="productModelChange? 'product-model-change': ''"
      >
        {{ badge }}
      </mi-badge>
    </slot>
  </q-tab>
</template>

<script>
  import MiBadge from '@/packages/@mi-library/MiBadge/MiBadge.vue'

  export default {
    name: 'MiTab',
    components: { MiBadge },
    props: {
      badge: {
        type: [Number, String],
        required: false,
        default: ''
      },
      badgeColor: {
        type: String,
        required: false,
        default: ''
      },
      productModelChange: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      icon: {
        type: String,
        required: false,
        default: undefined
      },
      label: {
        type: [Number, String],
        required: false,
        default: ''
      },
      ripple: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* stylelint-disable declaration-no-important */
  .mi-tab {
    min-height: $mi-tab-min-height;
    padding: $mi-tab-padding;
    color: $mi-tab-text-color;

    &.q-tab--inactive {
      opacity: 1;
    }

    &.disabled,
    &[disabled] {
      opacity: 1 !important;
      color: $mi-silver-200;

      ::v-deep(.mi-badge) {
        background-color: $mi-silver-400;
      }
    }

    ::v-deep(.q-tab__icon) {
      width: $mi-tab-icon-size;
      height: $mi-tab-icon-size;
      font-size: $mi-tab-icon-size;
    }

    ::v-deep(.q-tab__label) {
      letter-spacing: .4px;
    }

    ::v-deep(.mi-badge) {
      margin-left: .5rem;
      transition: background .3s;
    }

    .product-model-change {
      padding: 2px 8px;
      width: fit-content;
      height: 18px;
      border-radius: 10px;
      font-size: 14px;
    }

    ::v-deep(.q-focus-helper):before,
    ::v-deep(.q-focus-helper):after {
      background-color: $mi-tab-hover-background-color;
    }

    &.q-tab--active ::v-deep(.mi-badge) {
      background: $mi-tab-active-text-color;
    }

    &.q-hoverable:hover > ::v-deep(.q-focus-helper),
    &.q-focusable:focus > ::v-deep(.q-focus-helper) {
      opacity: 1;
      background-color: $mi-tab-hover-background-color;

      &:before,
      &:after {
        opacity: 1;
      }
    }
  }
</style>
