<template>
  <q-tooltip
    class="mi-tooltip"
    :class="{ 'mi-tooltip--light': light }"
    :anchor="anchor"
    :delay="delay"
    :hide-delay="hideDelay"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :offset="offset"
    :self="self"
    :target="target"
    transition-show="jump-up"
    transition-hide="jump-down"
  >
    <slot></slot>
  </q-tooltip>
</template>

<script>
  export default {
    name: 'MiTooltip',
    props: {
      anchor: {
        type: String,
        required: false,
        default: 'top middle'
      },
      delay: {
        type: Number,
        required: false,
        default: 0
      },
      hideDelay: {
        type: Number,
        required: false,
        default: 0
      },
      light: {
        type: Boolean,
        required: false,
        default: false
      },
      maxHeight: {
        type: String,
        required: false,
        default: ''
      },
      maxWidth: {
        type: String,
        required: false,
        default: ''
      },
      offset: {
        type: Array,
        required: false,
        default: () => [0, 4]
      },
      self: {
        type: String,
        required: false,
        default: 'bottom middle'
      },
      target: {
        type: [Boolean, String],
        required: false,
        default: undefined
      }
    }
  }
</script>

<style lang="scss">
  .mi-tooltip--light {
    background-color: $mi-tooltip-light-background-color;
    color: $mi-tooltip-light-text-color;
  }
</style>
