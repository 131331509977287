<template>
  <q-tab-panel :draggable="false" :disable="disable">
    <slot></slot>
  </q-tab-panel>
</template>

<script>
  export default {
    name: 'MiTabPanel',
    props: {
      disable: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
