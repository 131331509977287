<template>
  <q-scroll-area ref="qScrollArea" class="mi-scroll-area" :delay="delay" :thumb-style="thumbStyle">
    <slot></slot>
  </q-scroll-area>
</template>

<script>
  // Thumb default styles
  const defaultThumbStyle = {
    right: '2px',
    borderRadius: '6px',
    width: '7px',
    opacity: 0.4,
    backgroundColor: '#bfbfbf'
  }

  export default {
    name: 'MiScrollArea',
    props: {
      delay: {
        type: [Number, String],
        required: false,
        default: 600
      },
      thumbStyle: {
        type: Object,
        required: false,
        default: () => defaultThumbStyle
      }
    },
    methods: {
      setScrollPosition(axis, offset, duration) {
        this.$refs.qScrollArea?.setScrollPosition?.(axis, offset, duration)
      }
    }
  }
</script>
