<template>
  <q-virtual-scroll ref="qVirtualScroll">
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-virtual-scroll>
</template>

<script>
  export default {
    name: 'MiVirtualScroll',
    methods: {
      scrollTo(index, edge) {
        this.$refs.qVirtualScroll?.scrollTo?.(index, edge)
      },
      reset() {
        this.$refs.qVirtualScroll?.reset?.()
      },
      refresh(index) {
        this.$refs.qVirtualScroll?.refresh?.(index)
      }
    }
  }
</script>
