<template>
  <mi-btn
    :icon="icon"
    :icon-size="iconSize"
    :class="{ 'mi-tr-action-btn--persistent': persistent }"
    class="mi-tr-action-btn"
    dense
    fab
  >
    <slot></slot>
  </mi-btn>
</template>

<script>
  export default {
    name: 'MiTrActionBtn',
    props: {
      icon: {
        type: String,
        required: false,
        default: ''
      },
      iconSize: {
        type: String,
        required: false,
        default: '10px'
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-tr-action-btn.mi-btn.q-btn--dense.q-btn--fab {
    width: $mi-table-row-action-button-size;
    min-width: $mi-table-row-action-button-size;
    min-height: $mi-table-row-action-button-size;
    padding-top: 2px;
    padding-bottom: 2px;

    &:not(.mi-tr-action-btn--persistent) {
      opacity: 0;
    }

    ::v-deep(.mi-icon) {
      margin: 0;
    }

    // stylelint-disable declaration-no-important
    &:hover {
      background-color: $mi-red-500 !important;
    }

    & + & {
      margin-left: .25rem;
    }
  }
</style>
