<template>
  <q-spinner :color="color" :size="size" :thickness="parseInt(thickness, 10)"></q-spinner>
</template>

<script>
  export default {
    name: 'MiSpinner',
    props: {
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      size: {
        type: String,
        required: false,
        default: ''
      },
      thickness: {
        type: [Number, String],
        required: false,
        default: 5
      }
    }
  }
</script>
