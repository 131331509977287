<template>
  <q-select
    ref="qSelect"
    class="mi-field mi-select"
    behavior="menu"
    dropdown-icon="caret-down"
    :bg-color="bgColor"
    :bottom-slots="bottomSlots"
    :clearable="clearable"
    :color="color"
    :dense="dense"
    :disable="disable"
    :emit-value="emitValue"
    :hide-bottom-space="!rules"
    :hide-dropdown-icon="hideDropdownIcon"
    :hide-hint="hideHint"
    :hint="hint"
    :input-debounce="inputDebounce"
    :label="label"
    :label-color="labelColor"
    :label-slot="labelSlot"
    :lazy-rules="lazyRules"
    :loading="loading"
    :map-options="mapOptions"
    :max-values="maxValues"
    :multiple="multiple"
    :option-disable="optionDisable"
    :option-label="optionLabel"
    :option-value="optionValue"
    :options="options"
    :options-dense="optionsDense"
    :options-selected-class="optionsSelectedClass"
    :outlined="outlined"
    :rules="rules"
    :use-input="useInput"
    :new-value-mode="newValueMode"
    no-error-icon
    stack-label
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-select>
</template>

<script>
  export default {
    name: 'MiSelect',
    props: {
      bgColor: {
        type: String,
        required: false,
        default: ''
      },
      bottomSlots: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      emitValue: {
        type: Boolean,
        required: false,
        default: false
      },
      hideDropdownIcon: {
        type: Boolean,
        required: false,
        default: false
      },
      hideHint: {
        type: Boolean,
        required: false,
        default: false
      },
      hint: {
        type: String,
        required: false,
        default: undefined
      },
      inputDebounce: {
        type: [Number, String],
        required: false,
        default: undefined
      },
      label: {
        type: String,
        required: false,
        default: undefined
      },
      labelColor: {
        type: String,
        required: false,
        default: ''
      },
      labelSlot: {
        type: Boolean,
        required: false,
        default: false
      },
      lazyRules: {
        type: [Boolean, String],
        required: false,
        default: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      mapOptions: {
        type: Boolean,
        required: false,
        default: false
      },
      maxValues: {
        type: [Number, String],
        required: false,
        default: undefined
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false
      },
      optionDisable: {
        type: [Function, String],
        required: false,
        default: 'disable'
      },
      optionLabel: {
        type: [Function, String],
        required: false,
        default: 'label'
      },
      optionValue: {
        type: [Function, String],
        required: false,
        default: 'value'
      },
      options: {
        type: Array,
        required: false,
        default: () => []
      },
      optionsDense: {
        type: Boolean,
        required: false,
        default: false
      },
      optionsSelectedClass: {
        type: String,
        required: false,
        default: undefined
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => []
      },
      useInput: {
        type: Boolean,
        required: false,
        default: false
      },
      newValueMode: {
        type: String,
        required: false,
        default: 'add'
      }
    },
    methods: {
      hidePopup() {
        this.$refs.qSelect?.hidePopup?.()
      },
      showPopup() {
        this.$refs.qSelect?.showPopup?.()
      }
    }
  }
</script>

<style lang="scss">
  .mi-select.q-field.q-field--auto-height .q-field__control,
  .mi-select.q-field.q-field--auto-height .q-field__native,
  .mi-select.q-field.q-field--auto-height .q-field__marginal {
    min-height: 40px;
    height: auto;
  }

  .mi-select .q-field__marginal .q-select__dropdown-icon {
    font-size: .5em;
    color: $mi-anthracite-800;
  }

  .mi-select:not(.q-field--dense) .q-field__marginal .q-select__dropdown-icon {
    margin-top: 2px;
  }

  .mi-select.q-field.q-field--dense {
    font-size: 12px;

    .q-field__control,
    .q-field__native,
    .q-field__marginal {
      min-height: 24px;
    }

    .q-field__control .q-field__native {
      padding: 0;
    }

    .q-field__marginal .q-select__dropdown-icon {
      font-size: .4em;
      line-height: 1.2;
    }
  }
</style>
