<template>
  <q-tab-panels ref="qTabPanels" :animated="animated">
    <slot></slot>
  </q-tab-panels>
</template>

<script>
  export default {
    name: 'MiTabPanels',
    props: {
      animated: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    methods: {
      goTo(panelName = '') {
        this.$refs.qTabPanels?.goTo?.(panelName)
      }
    }
  }
</script>
