<template>
  <q-skeleton
    class="mi-skeleton"
    animation="wave"
    :class="skeletonTypeClass"
    :type="quasarType"
    :style="{ width, height }"
  >
    <slot></slot>
  </q-skeleton>
</template>

<script>
  const skeletonTypes = {
    text: 'text',
    rect: 'rect',
    circle: 'circle',
    btn: 'QBtn',
    badge: 'QBadge',
    chip: 'QChip',
    toolbar: 'QToolbar',
    checkbox: 'QCheckbox',
    radio: 'QRadio',
    switch: 'QToggle',
    slider: 'QSlider',
    range: 'QRange',
    'text-field': 'QInput'
  }

  export default {
    name: 'MiSkeleton',
    props: {
      height: {
        type: String,
        required: false,
        default: ''
      },
      type: {
        type: String,
        required: false,
        default: 'rect',
        validator: value => [...Object.keys(skeletonTypes)].includes(value)
      },
      width: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      quasarType() {
        return skeletonTypes[this.type]
      },
      skeletonTypeClass() {
        return (this.type in skeletonTypes) ? `mi-skeleton--type-${ this.type }` : ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-skeleton--type-text {
    transform: scale(1, .75);
  }

  .mi-skeleton--type-btn {
    height: $mi-button-height;
    width: 6rem;
  }

  .mi-skeleton--type-badge {
    height: .75rem;
    width: 1rem;
  }

  .mi-skeleton--type-chip {
    width: 3.5rem;
    height: 1.5rem;
    border-radius: 0;
  }

  .mi-skeleton--type-checkbox {
    width: $mi-checkbox-icon-size;
    height: $mi-checkbox-icon-size;
    border-radius: 0;
  }

  .mi-skeleton--type-radio {
    width: $mi-radio-icon-size;
    height: $mi-radio-icon-size;
  }

  .mi-skeleton--type-switch {
    width: $mi-switch-font-size;
    height: $mi-switch-font-size / 2;
    border-radius: $mi-switch-border-radius;
  }

  .mi-skeleton--type-text-field {
    height: 2.5rem;
  }
</style>
