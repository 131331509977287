<template>
  <q-radio
    class="mi-radio"
    :color="color"
    :disable="disable"
    :label="label"
    :val="val"
    dense
  ></q-radio>
</template>

<script>
  export default {
    name: 'MiRadio',
    props: {
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        required: false,
        default: ''
      },
      val: {
        type: [Number, String],
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-radio {
    font-size: $mi-radio-label-font-size;
    line-height: 1.25;
    color: $mi-radio-label-text-color;

    ::v-deep(.q-radio__inner) {
      font-size: $mi-checkbox-icon-size * 2;
      color: $mi-radio-icon-border-color;

      &:before {
        display: none;
      }
    }

    &.disabled,
    &[disabled] {
      color: $mi-silver-300;

      ::v-deep(.q-radio__bg) {
        color: $mi-silver-300;
      }

      ::v-deep(.q-radio__inner--falsy) .q-radio__bg {
        background-color: currentColor;
        border-radius: 50%;
      }
    }
  }
</style>
