<template>
  <q-step
    class="mi-step"
    :active-color="activeColor"
    :active-icon="activeIcon"
    :color="color"
    :done="done"
    :done-color="doneColor"
    :done-icon="doneIcon"
    :error="error"
    :error-color="errorColor"
    :error-icon="errorIcon"
    :header-nav="false"
    :icon="icon"
    :title="title"
  >
    <slot></slot>
  </q-step>
</template>

<script>
  export default {
    name: 'MiStep',
    props: {
      activeColor: {
        type: String,
        required: false,
        default: ''
      },
      activeIcon: {
        type: String,
        required: false,
        default: ''
      },
      color: {
        type: String,
        required: false,
        default: ''
      },
      done: {
        type: Boolean,
        required: false,
        default: false
      },
      doneColor: {
        type: String,
        required: false,
        default: ''
      },
      doneIcon: {
        type: String,
        required: false,
        default: ''
      },
      error: {
        type: Boolean,
        required: false,
        default: false
      },
      errorColor: {
        type: String,
        required: false,
        default: ''
      },
      errorIcon: {
        type: String,
        required: false,
        default: ''
      },
      icon: {
        type: String,
        required: false,
        default: ''
      },
      title: {
        type: String,
        required: true
      }
    }
  }
</script>
