<template>
  <q-tr
    :no-hover="noHover"
    :props="props"
    :class="{ 'mi-tr--selected': selected }"
    class="mi-tr"
  >
    <slot></slot>
  </q-tr>
</template>

<script>
  export default {
    name: 'MiTr',
    props: {
      noHover: {
        type: Boolean,
        required: false,
        default: false
      },
      props: {
        type: Object,
        required: false,
        default: () => {}
      },
      selected: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-tr--selected {
    background-color: $mi-table-row-selected-background-color;
  }
</style>
