<template>
  <q-menu
    anchor="bottom left"
    self="top left"
    :auto-close="autoClose"
    :fit="fit"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :no-parent-event="noParentEvent"
    :persistent="persistent"
    :target="target"
  >
    <slot></slot>
  </q-menu>
</template>

<script>
  export default {
    name: 'MiMenu',
    props: {
      autoClose: {
        type: Boolean,
        required: false,
        default: false
      },
      fit: {
        type: Boolean,
        required: false,
        default: false
      },
      maxHeight: {
        type: String,
        required: false,
        default: ''
      },
      maxWidth: {
        type: String,
        required: false,
        default: ''
      },
      noParentEvent: {
        type: Boolean,
        required: false,
        default: false
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      },
      target: {
        type: [Boolean, Number, String],
        required: false,
        default: true
      }
    }
  }
</script>
