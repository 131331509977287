<template>
  <q-table
    ref="qTable"
    :columns="columns"
    :expanded="expanded"
    :hide-bottom="hideBottom"
    :hide-header="hideHeader"
    :hide-no-data="hideNoData"
    :hide-pagination="hidePagination"
    :hide-selected-banner="hideSelectedBanner"
    :loading="loading"
    :loading-label="loadingLabel"
    :no-data-label="noDataLabel"
    :no-results-label="noResultsLabel"
    :pagination="pagination"
    :rows="rows"
    :rows-per-page-options="rowsPerPageOptions"
    :separator="separator"
    :table-class="tableClasses"
    :table-header-class="headerClasses"
    :title="title"
    :wrap-cells="wrapCells"
    class="mi-table-wrapper"
    title-class="mi-table__title text-weight-bold"
    flat
    square
  >
    <!-- Loading -->
    <template #loading>
      <mi-inner-loading :showing="loading">
        <mi-spinner size="50px"></mi-spinner>
        <h6 class="q-mt-lg q-mb-none"> {{ loadingLabel }} </h6>
      </mi-inner-loading>
    </template>

    <template v-if="customHeader" #header="props">
      <q-tr :props="props" class="bg-white mi-table__header mi-table__header--sticky">
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          class="text-left sortable sorted"
        >
          <div
            v-if="col.sortable"
            @mouseenter="onChangeSortTableColumnVisibility(col.name, true)"
            @mouseleave="onChangeSortTableColumnVisibility(col.name, false)"
            @click="onChangeSortTableDirection(col.name)"
          >
            {{ col.label }}
            <i
              v-if="col.showSortElement"
              :class="createSortDirectionClass(col.sortDirection)"
              class="q-table__sort-icon q-table__sort-icon--left"
              aria-hidden="true"
              role="presentation"
            > </i>
          </div>
          <div v-else>
            {{ col.label }}
          </div>
        </q-th>
      </q-tr>
    </template>

    <!-- No data -->
    <template #no-data="{ message }">
      <div v-if="!loading" class="column flex-center">
        <mi-icon name="warning-circle" size="1.75rem"></mi-icon>
        <h6 class="q-mt-sm q-mb-none"> {{ message }} </h6>
      </div>
    </template>

    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-table>
</template>

<script>
  export default {
    name: 'MiTable',
    props: {
      columns: {
        type: Array,
        required: false,
        default: () => []
      },
      expanded: {
        type: Array,
        required: false,
        default: () => []
      },
      hideBottom: {
        type: Boolean,
        required: false,
        default: false
      },
      hideHeader: {
        type: Boolean,
        required: false,
        default: false
      },
      hideNoData: {
        type: Boolean,
        required: false,
        default: false
      },
      hidePagination: {
        type: Boolean,
        required: false,
        default: false
      },
      hideSelectedBanner: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      loadingLabel: {
        type: String,
        required: false,
        default: 'Loading data, please wait ...'
      },
      noDataLabel: {
        type: String,
        required: false,
        default: 'No data found'
      },
      noResultsLabel: {
        type: String,
        required: false,
        default: 'No matched results'
      },
      pagination: {
        type: Object,
        required: false,
        default: () => ({ rowsPerPage: 0 })
      },
      rows: {
        type: Array,
        required: false,
        default: () => []
      },
      rowsPerPageOptions: {
        type: Array,
        required: false,
        default: () => [50]
      },
      separator: {
        type: String,
        required: false,
        default: 'none'
      },
      stickyHeader: {
        type: Boolean,
        required: false,
        default: false
      },
      striped: {
        type: Boolean,
        required: false,
        default: false
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      wrapCells: {
        type: Boolean,
        required: false,
        default: false
      },
      customHeader: {
        type: Boolean,
        required: false,
        default: false
      },
      onChangeSortTableColumnVisibility: {
        type: Function,
        required: false,
        default: () => {}
      },
      onChangeSortTableDirection: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    computed: {
      headerClasses() {
        const classes = ['bg-white mi-table__header']

        if (this.stickyHeader) {
          classes.push('mi-table__header--sticky')
        }

        return classes.join(' ')
      },
      tableClasses() {
        const classes = ['mi-table']

        if (this.striped) {
          classes.push('mi-table--striped')
        }

        return classes.join(' ')
      }
    },
    methods: {
      scrollTo(index, edge) {
        this.$refs.qTable?.scrollTo?.(index, edge)
      },
      setExpanded(expanded = []) {
        this.$refs.qTable?.setExpanded?.(expanded)
      },
      sort(col = '') {
        this.$refs.qTable?.sort?.(col)
      },
      createSortDirectionClass(direction) {
        return `q-icon mi-icon-caret-${ direction === 'DESC' ? 'down' : 'up' }`
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable selector-max-compound-selectors
  .mi-table-wrapper {
    ::v-deep(.q-table__top) {
      padding-left: 0;
      padding-right: 0;
    }

    ::v-deep(.mi-table__title) {
      letter-spacing: 0;
      line-height: 1.875rem;
    }

    ::v-deep(.mi-table) .mi-table__header,
    ::v-deep(.mi-table) tbody td {
      height: $mi-table-header-height;
    }

    ::v-deep(.mi-table) .mi-table__header th {
      font-size: .875rem;
      font-weight: 700;
      color: $mi-anthracite-800;
      border-color: $mi-white;
      border-bottom: 2px solid $mi-anthracite-800;
      white-space: nowrap;
    }

    ::v-deep(.mi-table) .mi-table__header--sticky th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    ::v-deep(.mi-table):not(.mi-table--no-hover) tbody tr:not(.q-tr--no-hover):hover {
      background-color: $mi-table-row-hover-background-color;

      .mi-tr-action-btn {
        opacity: 1;
      }
    }

    ::v-deep(.mi-table) tbody td {
      font-size: .875rem;
      color: $mi-anthracite-900;

      // stylelint-disable declaration-no-important
      &:before {
        content: none !important;
      }
    }

    ::v-deep(.mi-table) .q-table__sort-icon {
      font-size: 10px;
      margin-left: .5rem;
    }

    ::v-deep(.mi-table--striped) tbody tr:not(.mi-tr--selected):nth-child(even) {
      background-color: $mi-silver-50;
    }

    ::v-deep(.q-table__bottom--nodata) {
      position: absolute;
      top: $mi-table-header-height + $mi-table-top-height;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      border: 0;
    }
  }

  ::v-deep(.q-table__top) {
    padding: 12px 16px 8px;
  }

  ::v-deep(.q-table__title) {
    color: $mi-anthracite-800;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
</style>
