import Notify from 'quasar/src/plugins/Notify.js';

export default ({ type = '', textColor = 'white', color, icon } = {}) => {
  const additionalClass = type ? ` mi-notification--${ type }` : ''
  Notify.registerType(type, {
    textColor,
    color,
    icon,
    classes: `mi-notification${ additionalClass }`
  })
}
