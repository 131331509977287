<template>
  <q-item-label :caption="caption" :header="header" :overline="overline">
    <slot></slot>
  </q-item-label>
</template>

<script>
  export default {
    name: 'MiListItemLabel',
    props: {
      caption: {
        type: Boolean,
        required: false,
        default: false
      },
      header: {
        type: Boolean,
        required: false,
        default: false
      },
      overline: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
